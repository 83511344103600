<template src="./pharmaciesTopmed.html"></template>

<script>
import { useStore } from "@/store/store.js"
import { computed } from "vue"
import mixins from "@/mixins/mixins.js"
import WholeMultiselect from "@/components/whole-multiselect/WholeMultiselect.vue"

export default {
  name: "medicine",
  mixins: [mixins],
  components: { WholeMultiselect },
  data: () => ({
    patient_id: "",
    neighborhood: null,
    neighborhoodList: [],
    neighborhoodFormShow: false,
    pharmacies: [],
    pharmaciesRun: false,
    pharmaciesPaginated: [],
    baseSearch: {},
    cep: "",
    cepInvalid: false,
    gettingLocation: false,
    numPages: 1,
    currentPage: 1,
    windowWidth: 0,
    modalMapShow: false,
    modalMapCenter: null,
    pharmacySelected: null,
  }),
  computed: {
    isCepDisabled () {
      return this.cep.length < 9
    },
    isPaginated () {
      return this.numPages > 1
    },
  },
  setup () {
    const store = useStore()

    return {
      isMobile: computed(() => store.getters.isMobile),
      getGoogleAddress: (data) => store.dispatch("getGoogleAddress", data),
      getAddressByZipcode: (cep) => store.dispatch("getAddressByZipcode", cep),
    }
  },
  mounted () {
    this.patient_id = this.$session.get("id")
  },
  methods: {
    async searchPharmacies () {
      this.cepInvalid = false

      const response = await this.getAddressByZipcode(this.cep)

      if (response && response.address) {
        this.baseSearch = {
          bairro: response.address.neighborhood,
          cep: response.address.zipcode,
          localidade: response.address.city,
          uf: response.address.state,
        }
        this.cepInvalid = !this.baseSearch.bairro && !this.baseSearch.uf

        await this.getNeighborhoods()
        await this.getPharmaciesP()
      } else {
        this.showNotifyWarning(
          `Não foi possível localizar o endereço através do CEP digitado.
          Digite um CEP válido e tente novamente.`,
          6000
        )
      }
    },

    getNeighborhoods() {      
      this.$vs.loading()
      this.numPages = 0

      this.axios.post(
        `${this.$store.state.filooServer}topmed/get-neighborhoods`,
        { cidade: this.baseSearch.localidade, uf: this.baseSearch.uf },
      )
        .then(({status, data: neighborhoods}) => {
          if (status === 200) {
            this.neighborhoodList = neighborhoods.map((obj) => obj.nome)
          }
        }).finally(() => {
          this.$vs.loading.close()
        });
    },

    resetSearch(){
      this.pharmaciesRun = false
      this.neighborhood = null
      this.neighborhoodFormShow = false
      this.pharmaciesPaginated = []
      this.cep = ""
    },

    getPharmaciesP() {
      this.$vs.loading()

      if (this.baseSearch) {
        this.axios.post(`${this.$store.state.filooServer}topmed/farma`, this.baseSearch)
          .then((response) => {
            this.numPages = Math.round(response.data.length / 9)

            this.pharmacies = response.data.map((pharmacy) => ({
              ...pharmacy,
              nameClear: pharmacy.nome.split("-")[0],
              distancia: pharmacy.distancia.replace(".", ","),
            }))

            this.pharmacies.sort((a, b) => a.distancia - b.distancia)
            this.pharmaciesPaginated = this.pharmacies.slice(0, 9)
          })
          .catch((error) => {
            console.warn(error)
          })
          .finally(() => {
            this.$vs.loading.close()

            if(!this.pharmaciesPaginated.length) {
              this.neighborhoodFormShow = true
              this.numPages = 0

              if (!this.neighborhoodList.length) {
                this.getNeighborhoods(this.baseSearch)
              }
            }
          })
      } else {
        this.baseSearch.bairro = this.neighborhood
      }
    },
    
    modalMapClose () {
      this.modalMapShow = false
      this.modalMapCenter = {}
      this.pharmacySelected = null
    },
    
    async modalMapOpen(pharma) {
      this.pharmacySelected = pharma

      await this.axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${pharma.cep}&key=AIzaSyAdwxi7P7SUDsbcxa3RRcQ315FDIOh1log`
      )
        .then(({ data }) => {          
          const lat = parseFloat(data.results[0].geometry.location.lat)
          const lng = parseFloat(data.results[0].geometry.location.lng)

          this.modalMapCenter = { lat, lng }
          this.modalMapShow = true

          setTimeout(() => {
            this.$bvModal.show("modal-pharm-location")
          })            
        })
    },

    changePagination () {
      this.pharmaciesPaginated = this.pharmacies.slice(
        (this.currentPage - 1) * 9,
        this.currentPage * 9
      )

      window.scrollTo({ top: 0, behavior: "smooth" })
    },
  },
  watch: {
    currentPage () {
      this.changePagination()
    },
  },
};
</script>

<style lang="scss" src="./pharmaciesTopmed.scss">
</style>
